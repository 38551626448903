:root {
  --text-3-5xl: 24px;
  --text-3-8xl: 34px;
  --text-4xl: 36px;
}

body {
  background-color: #FFFFFF;
}

input[type=checkbox] {
  -webkit-appearance: auto;
     -moz-appearance: auto;
          appearance: auto;
}

.course-image {
  height: 168px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.default-image {
  background-color: var(--avatar-frame-bg);
  color: var(--avatar-frame-color);
  display: flex;
  flex-direction: column;
}

.default-image-text {
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: normal;
  font-size: 7rem;
  font-weight: bold;
}

.course-tags {
  display: flex;
  position: relative;
  top: 1rem;
  left: 1rem;
}

.course-image .course-tags {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.course-card-pills {
    background: #ffffff;
    margin-left: 0;
    margin-right: 0.5rem;
    border-radius: var(--border-radius);
    padding: 3.5px 8px;
    font-size: 11px;
    text-align: center;
    letter-spacing: 0.011em;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--gray-900);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: var(--shadow-sm);
}

.dark-pills {
    background: rgba(25, 39, 52, 0.8);
    color: #ffffff;
}
.dark-pills img {
    width: 0.75rem;
    height: 0.75rem;
}

.common-page-style {
  padding: 2rem 0 5rem;
  padding-top: 3rem;
  background-color: var(--bg-color);
}

.common-card-style {
  display: flex;
  background: #FFFFFF;
  border-radius: var(--border-radius-md);
  position: relative;
  box-shadow: var(--shadow-sm);
}

.course-card {
  flex-direction: column;
  height: 100%;
  min-height: 350px;
  overflow: auto;
}

.muted-text {
  font-size: 12px;
  line-height: 135%;
}

.course-card-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--text-base);
  color: var(--gray-900);
  margin-bottom: 0.5rem;
}

.icon {
  margin: 0;
  margin-right: 0.25rem;
}

.lesson-links .icon {
  stroke: none;
}

.course-card-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  color: var(--text-color);
}

.course-card-title {
    font-weight: 600;
    color: var(--gray-900);
    margin-bottom: 1.25rem;
    font-size: 1.125rem;
}

.card-divider {
  border-top: 1px solid var(--gray-300);
  margin-bottom: 1rem;
}

.card-divider-dark {
  border-top: 1px solid #C8CFD5;
  margin-bottom: 16px;
}

.course-instructor {
  margin-left: 0.5rem;
  font-size: var(--text-base);
}

@media (max-width: 400px) {
  .course-instructor {
    margin-left: 0;
  }
}

.course-student-count {
  display: flex;
  font-size: var(--text-base);
  float: right;
  color: var(--gray-900);
}

.course-card-footer {
  margin-top: auto;
}

.course-card-footer .avatar-group {
  display: inherit;
}

.view-course-link {
  height: 32px;
  border-radius: var(--border-radius-sm);
  font-size: 12px;
  padding: 8px 0px 8px;
  text-align: center;
  line-height: 135%;
  cursor: pointer;
}

.cards-parent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  -moz-column-gap: 40px;
  column-gap: 40px;
  row-gap: 40px;
  align-items: center;
}

@media (max-width: 767px) {
  .cards-parent {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    -moz-column-gap: 16px;
    column-gap: 16px;
    row-gap: 16px;
  }
}

@media (max-width: 375px) {
  .cards-parent {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    -moz-column-gap: 24px;
    column-gap: 24px;
    row-gap: 24px;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.button-links {
  color: var(--gray-900);
}

.button-links:hover {
  text-decoration: none;
  color: var(--gray-900);
}

.icon-background {
  border-radius: 50%;
  padding: 3px;
  width: 24px;
  height: 24px;
}

.review-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.review-content {
  margin: 1rem 0 0 3.5rem;
  color: var(--gray-700);
}

.custom-checkbox>label>input {
  visibility: hidden;
}

.custom-checkbox>label>.empty-checkbox {
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid black;
  border-radius: var(--border-radius-md);
}

.empty-checkbox {
  margin-right: 0.5rem;
}

.custom-checkbox>label>input:checked+.empty-checkbox {
  background: url(/assets/lms/icons/tick.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.quiz-label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
}

.quiz-label p {
  display: inline;
}

.course-card-wide {
  width: 50%;
  font-size: var(--text-base);
}

@media (max-width: 1000px) {
  .course-card-wide {
    width: 75%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .course-card-wide {
    width: 100%;
    margin: 0;
  }
}

.course-card-wide-content {
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: space-between;
}

.course-card-wide-title {
  font-weight: 700;
  font-size: var(--text-4xl);
  line-height: 44px;
  color: var(--gray-900);
  margin: 0.25rem 0;
}

.button {
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1.25rem;
  font-size: var(--text-md);
  line-height: 20px;
  box-shadow: var(--btn-shadow);
  border: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.button:disabled {
  cursor: not-allowed;
}

.wide-button {
  padding: 0.5rem 6rem;
  font-weight: 500;
  width: 100%;
}

@media (max-width: 768px) {
  .wide-button {
    padding: 0.5rem 4rem;
  }
}

.is-secondary {
  background: #FFFFFF;
  color: var(--gray-900);
}

.is-secondary:hover {
  text-decoration: none;
  color: var(--gray-900);
}

.is-default {
  background: var(--gray-100);
  color: var(--gray-900);
}

.is-default:disabled {
  color: var(--gray-500);
}

.is-primary {
  background: var(--primary-color);
  color: #FFFFFF;
}

.is-primary:hover {
  text-decoration: none;
  color: #FFFFFF;
}

.course-home-page .course-home-outline {
    padding-bottom: 4rem;
}

.course-home-page {
  background-color: #FFFFFF;
  padding-top: 4rem;
}

.chapter-title {
    cursor: pointer;
    border-radius: var(--border-radius-lg);
    color: var(--gray-900);
    display: flex;
    align-items: center;
}

.chapter-description {
    color: var(--gray-900);
    font-size: var(--text-sm);
}

.course-content-parent .chapter-description {
  font-size: 0.75rem;
}

.chapter-icon {
  margin-right: 0.5rem;
}

.reviews-parent {
  padding-bottom: 5rem;
  color: var(--gray-900);
}

.lesson-info {
  font-size: 16px;
  color: var(--gray-900);
  letter-spacing: -0.011em;
}

.lesson-links {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    color: var(--gray-900);
}

.lesson-links:hover {
  cursor: pointer;
  text-decoration: none;
  color: var(--gray-900);
  border-radius: var(--border-radius-md);
}

.lessons {
    margin-left: 1.5rem;
}

.member-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
}

.member-card .talk-title {
  font-weight: bold;
}

.break {
    flex-basis: 100%;
    flex-grow: 1;
    margin: 0.5rem 0;
}

.course-home-headings {
  font-weight: 600;
  font-size: var(--text-3-5xl);
  letter-spacing: -0.0175em;
  color: var(--gray-900);
  margin-bottom: 1rem;
}

.modal-headings {
  margin: 0;
}

.avatar-square {
  width: 130px;
  height: 130px;
}

.avatar-square .avatar-frame {
  border-radius: 20px;
}

.avatar-medium {
  width: 42px;
  height: 42px;
}

.avatar-large {
  width: 68px;
  height: 68px;
}

.avatar-xl {
  width: 112px;
  height: 112px;
}

@media (max-width: 500px) {
  .avatar-square {
    width: 75px;
    height: 75px;
  }
}

.member-parent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  -moz-column-gap: 2rem;
  column-gap: 2rem;
  row-gap: 2rem;
}

@media (max-width: 600px) {
  .member-parent {
    grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    -moz-column-gap: 2rem;
    column-gap: 2rem;
    row-gap: 2rem;
  }
}

.view-all-mentors {
  width: 100%;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  margin-top: 1rem;
}

.review-modal .modal-dialog {
  width: 50%;
  height: 70%;
}

@media (max-width: 600px) {
  .review-modl .modal-dialog {
    width: auto;
  }
}

.error-field {
  color: red;
}

.quiz-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;
}

.question {
  flex-direction: column;
}

.question p {
  margin-bottom: 0;
}

.active-question .card-divider {
  margin-top: 1rem;
}

.dark-links {
  color: inherit;
}

.dark-links:hover {
  color: inherit;
}

.breadcrumb {
  display: flex;
  align-items: center;
  font-size: var(--text-base);
  line-height: 20px;
  color: var(--gray-900);
  padding: 0;
}

.course-details-outline {
  margin-top: 1rem;
}

.lesson-content {
    padding: 1.5rem;
    flex-direction: column;
}

.lesson-content-card {
    margin-top: 2rem;
}

.lesson-content-card .alert-dismissible .close {
  padding: 0.5rem;
}

.course-content-parent {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 2fr minmax(600px, 5fr);
}

@media (max-width: 1024px) {
  .course-content-parent {
    display: flex;
    flex-direction: column-reverse;
  }
}

.course-content-parent .course-home-headings {
    margin: 0 0 1rem;
    width: 100%;
}

.lesson-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem
}

.lesson-pagination-parent {
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .lesson-pagination-parent {
    margin-left: 0px;
  }
}

.lesson-video {
  width: 100%;
}

.active-lesson {
  background-color: var(--gray-200);
  border-radius: var(--border-radius-md);
}

.lesson-title {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.profile-page {
  padding-top: 0;
}

.profile-banner {
  height: 248px;
  background-size: cover;
  background-position: center;
}

@media (max-width: 500px) {
  .profile-banner {
    height: 150px;
  }
}

.profile-info {
  height: 90px;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  font-size: var(--text-sm);
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  padding-left: 200px;
  padding-right: 1rem;
  box-shadow: var(--shadow-sm);
}

@media (max-width: 550px) {
  .profile-info {
    align-items: flex-end;
    padding-left: 0;
    height: 150px;
  }
}

.profile-avatar {
  position: relative;
  top: 188px;
  left: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 500px) {
  .profile-avatar {
    top: 95px;
    left: 10px;
  }
}

@media (max-width: 375px) {
  .profile-avatar {
    top: 120px;
    left: 10px;
  }
}

.profile-name {
  color: var(--gray-900);
  font-weight: 600;
  font-size: var(--text-3xl);
}

@media (max-width: 375px) {
  .profile-name {
    font-size: var(--text-lg);
    padding-top: 5px;
  }
}

.profile-name-section {
  display: flex;
  align-items: center;
  margin: 1rem 0 0.25rem;
}

@media (max-width: 550px) {
  .profile-name-section {
    flex-direction: column;
    align-items: flex-end;
    margin: 0 0 0.5rem;
  }
}

.creator-badge {
  background: #48BB74;
  padding: 4px 6px;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.011em;
  text-transform: uppercase;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 16%);
  border-radius: var(--border-radius-sm);
  margin-left: 0.5rem;
}

@media (max-width: 375px) {
  .creator-badge {
    font-size: 8px;
  }
}

@media (max-width: 550px) {
  .creator-badge {
    margin-top: 0.25rem;
    margin-left: 0;
  }
}

.profile-grid-card {
  display: grid;
  row-gap: 1rem;
}

.profile-item {
  color: var(--gray-800);
  font-weight: 500;
}

.education-details {
  margin-top: 1.25rem;
}

.bold-title {
  font-weight: 500;
  color: var(--gray-800);
}

.profile-courses {
  margin-top: 3rem;
}

.zindex {
  position: relative;
  z-index: 2;
}

.progress {
  width: 100%;
  height: 4px;
}

.course-card-wide .progress {
  background-color: var(--gray-500);
}

.progress-bar {
  background-color: var(--primary-color);
}

.progress-percent {
  margin: 0.5rem 0;
  font-size: var(--text-base);
}

pre {
  width: 100%;
  overflow-x: auto;
}

.markdown-source h1 {
  font-size: 1.3rem;
}

.markdown-source h2 {
  font-size: 1.2rem;
}

.markdown-source h3 {
  font-size: 1.1rem;
}

.markdown-source h4 {
  font-size: 1rem;
}

.markdown-source p:last-child {
    margin-bottom: 0;
}

.avatar img {
  -o-object-fit: cover;
     object-fit: cover;
}

.certificate-content {
    background-color: #FFFFFF;
    border-width: 10px;
    border-style: solid;
}

@media (max-width: 500px) {
    .certificate-content {
        border-width: 50px;
    }
}

.certificate-footer {
    display: flex;
    justify-content: center;
    margin: 4rem auto 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.certificate-ribbon {
    background-color: var(--primary-color);
    padding: 0.5rem;
    border-radius: var(--border-radius-md);
}

.certificate-heading {
    font-size: 2rem;
    font-weight: 500;
    color: var(--text-color);
}

.certificate-para {
  margin-bottom: 4rem;
}

.certificate-card {
    background: #FFFFFF;
    border-radius: var(--border-radius-md);
    position: relative;
    box-shadow: var(--shadow-sm);
    padding: 1rem;
    text-align: center;
}

.certificate-footer-item {
    color: var(--text-color);
    font-weight: bold;
    font-family: cursive;
    font-size: 1.25rem;
}

.certificate-logo {
  height: 1.5rem;
}

@media (max-width: 768px) {
    .certificate-card {
        margin: 0;
    }
}

@media (max-width: 550px) {
    .certificate-content {
        padding: 1rem;
    }
}

.column-card {
    flex-direction: column;
    padding: 1.25rem;
}

.empty-state {
  background: var(--gray-200);
  border-radius: var(--border-radius-lg);
  padding: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-state-text {
    margin-left: 1rem;
}

.empty-state-heading {
  font-size: var(--text-xl);
  color: var(--text-color);
  font-weight: 600;
}

.vertically-center {
  display: flex;
  align-items: center;
}

.search {
  background-image: url(/assets/frappe/icons/timeless/search.svg);
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--text-sm);
  padding: 0.625rem 0.75rem;
  height: 36px;
  background-repeat: no-repeat;
  text-indent: 1.5rem;
  background-position: 1rem 0.7rem;
  width: 30%;
  box-shadow: var(--shadow-sm);
}

.course-search-header {
    float: right;
    width: 80%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 1250px) {
    .search {
        width: 40%;
    }
}

@media (max-width: 1000px) {
    .search {
        width: 55%;
    }

    .course-search-header {
        width: 75%;
    }
}

@media (max-width: 650px) {
    .course-search-header {
        width: 60%;
    }
}

@media (max-width: 550px) {
    .course-search-header {
        float: none;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
}

.section-heading {
  font-size: var(--text-4xl);
}

.testimonial-card {
  flex-direction: column;
  padding: 2rem;
  height: 100%;
}

.testimonial-author-name {
  font-weight: 500;
  font-size: var(--text-lg);
  color: var(--text-color);
}

.testimonial-review {
  color: var(--text-color);
  margin-bottom: 2rem;
}

.testimonial-footer {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--gray-200);
    padding-top: 2rem;
    margin-top: auto;
}

.testimonial-profession {
  font-size: 0.625rem;
}

.carousel-indicators li {
  background-color: var(--gray-600);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.carousel-control-prev-icon {
  background-image: url("/assets/lms/icons/slider-arrow-left.svg");
  width: 40px;
  height: 40px;
}

.carousel-control-next-icon {
  background-image: url("/assets/lms/icons/slider-arrow-right.svg");
  width: 40px;
  height: 40px;
}

.carousel-control-prev, .carousel-control-next {
  width: auto;
  top: 40%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: white;
  border-radius: 50%;
  box-shadow: var(--shadow-sm);
  opacity: 1;
}

.related-courses .carousel-control-prev, .related-courses .carousel-control-next {
  top: 40%;
}

.related-courses {
  background: var(--gray-50);
  padding: 5rem 0;
  position: relative;
  z-index: 5;
}

.carousel-indicators {
  position: inherit;
  margin: 0;
}

.carousel {
  padding: 0 1.5rem;
}

@media (max-width: 500px) {
  .carousel {
    padding: 0 0.5rem;
  }
}

.slider-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px auto 0;
}

.carousel-indicators li {
  border: none;
  margin: 0 6px;
}

.search-empty-state {
  position: relative;
  top: 1rem;
  margin-bottom: 5rem;
  width: 100%;
}

.close-search-empty-state {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close-search-empty-state:hover {
  text-decoration: none;
  color: #000;
}

.live-courses .course-home-headings {
  margin-bottom: 1.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media (min-width: 768px) {
  .lesson-pagination .custom-checkbox .empty-checkbox {
    width: 1rem;
    height: 1rem;
    border-radius: var(--border-radius-sm);
  }
}

@media (max-width: 767px) {
  .lesson-pagination .custom-checkbox .empty-checkbox {
    margin-bottom: 1rem;
    border-radius: var(--border-radius-sm);
  }

  .lesson-pagination .custom-checkbox span {
    display: inline-block;
    width: 70%;
    font-size: 10px;
  }
}

.lesson-pagination .custom-checkbox input:checked+.empty-checkbox {
  background-size: 1rem;
}

.no-discussions {
  width: 80% !important;
}

.preview-work {
  width: 50%;
}

.job-card {
    position: relative;
    padding: 1rem;
}

.company-logo {
    background-position: center;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--border-radius-sm);
    width: 50px;
    height: 50px;
    margin-right: 1rem;
}

.job-card-parent {
  display: grid;
  row-gap: 1rem;
}

.job-card-logo-section {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.job-detail-card {
  padding: 1rem;
  flex-direction: column;
}

.job-subtitle {
  font-size: var(--text-base);
  margin-bottom: 1.875rem;
}

.job-card-heading {
  font-weight: 600;
  color: var(--gray-900);
  margin-bottom: 0.5rem;
}

.course-head-container {
    color: var(--gray-900);
    background-color: var(--gray-50);
}

.seperator {
  margin: 0 1rem;
}

.course-overlay-card {
  background-color: white;
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-sm);
  overflow: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 10%;
  right: 7%;
  max-width: 400px;
  z-index: 4;
}

@media (max-width: 1000px) {
  .course-overlay-card {
    position: inherit;
    margin: 1rem auto;
  }

  .seperator {
    margin: 0 0.25rem;
  }
}

.course-overlay-content {
  padding: 1.25rem;
  font-size: var(--text-base);
  color: var(--text-color);
}

.breadcrumb-destination {
  color: var(--gray-600);
}

.preview-video {
  width: 100%;
  height: 190px;
  border: none;
}

.course-body-container {
  width: 60%;
}

@media (max-width: 1000px) {
  .course-body-container {
    width: 100%;
  }
}

.overlay-heading {
  margin-top: 2rem;
  font-weight: 600;
  color: var(--gray-800);
}

.overlay-student-count {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.course-creators-card {
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
  background-color: var(--gray-100);
  box-shadow: none;
}

.course-meta {
  font-size: var(--text-base);
  color: var(--gray-900);
}

.avg-rating {
  font-size: var(--text-3-8xl);
  color: var(--gray-900);
  font-weight: bold;
}

.reviews-header {
  display: flex;
  justify-content: space-between;
  width: 75%;
}

@media (max-width: 1250px) {
  .reviews-header {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .reviews-header {
    width: 100%;
  }
}

@media (max-width: 500px) {
    .reviews-header {
        flex-direction: column;
        align-items: center;
    }

    .vertical-divider {
        margin: 1rem;
    }
}

.bold-heading {
  font-size: var(--text-lg);
  color: var(--gray-900);
  font-weight: 600;
}

.star-click {
  --star-fill: var(--yellow-500);
  margin-right: 0;
}

.rating {
  --star-fill: var(--gray-400);
}

.vertical-divider {
    border: 1px solid var(--gray-300);
    margin: 0 1rem;
}

.avg-rating-stars {
  background: var(--gray-200);
  border-radius: 100px;
  padding: 0.5rem 0.75rem;
  margin: 1.25rem 0 0.5rem;
}

.reviews-parent .progress {
  width: 200px;
  color: var(--gray-900);
}

.reviews-parent .progress-bar {
  background-color: var(--gray-600);
}

.course-home-top-container {
  position: relative;
}

.question-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.question-number {
  padding-right: 0.25rem;
}

.option-text {
  padding: 0.75rem;
  border: 1px solid var(--gray-200);
  border-radius: var(--border-radius-md);
  flex: 1;
}

.active-option .option-text {
  background-color: var(--blue-50);
  border: 1px solid var(--blue-500);
}

.question-text {
  font-size: var(--text-lg);
  color: var(--gray-900);
  font-weight: 600;
  flex: 1;
  margin: 0 1rem;
}

.profile-page-body {
  background-color: var(--gray-50);
}

.profile-column-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}

.description {
  font-size: var(--text-base);
  color: var(--gray-800);
}

.profile-meta {
  display: flex;
  align-items: center;
}

.carousel-inner {
  overflow: inherit;
}

.lms-nav .nav-link {
    padding: var(--padding-sm) 0;
    margin: 0 var(--margin-md);
    font-size: var(--text-base);
    color: var(--text-muted);

}

.lms-nav .nav-link.active {
    font-weight: 500;
    border-bottom: 1px solid var(--primary-color);
    color: var(--primary-color);
}

@media (min-width: 500px) {
    .lms-nav .nav-item:first-child .nav-link {
        margin-left: 0;
    }
}

.dashboard-button {
    position: relative;
    top: -50px;
    margin-left: auto;
}

.course-card-wide .breadcrumb {
  margin-bottom: 0;
}

.course-content-parent .course-details-outline .course-home-headings {
  display: none;
}

.btn-outline-primary {
    border: 1px solid var(--primary-color);
}

.show-attachments {
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
}

.attachment-controls {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}

.attachments {
    flex-direction: column;
    padding: 0.5rem 0;
    margin-top: 1rem;
    position: absolute;
    z-index: 1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-collapse: separate;
    border-spacing: 1rem 0.5rem;
}

.attachments-parent {
    color: var(--text-color);
}

li {
    line-height: 1.7;
}

.course-overlay-title {
    font-weight: 700;
    font-size: var(--text-2xl);
    line-height: 1rem;
    color: var(--gray-900);
    margin-bottom: 1.25rem;
}

.course-card-wide .avatar .standard-image {
    border: 1px solid var(--gray-400);
}

.lesson-progress-tick {
    margin: 0 0.5rem
}

.no-preview {
    color: var(--gray-600);
}

.discussions-parent .empty-state {
    background-color: var(--gray-200);
}

.job-cards-parent {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    -moz-column-gap: 40px;
    grid-gap: 1rem;
    align-items: center;
}

.job-company {
    display: flex;
    align-items: center;
}

.job-actions {
    display: flex;
    align-items: flex-start;
    margin-left: auto;
    margin-bottom: 1rem;
}

.job-detail-header {
    display: flex;
}

@media (max-width: 600px) {
    .job-company {
        flex-direction: column;
        align-items: inherit;
    }
}

@media (max-width: 1200px) {
    .job-cards-parent {
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
}

@media (max-width: 500px) {
    .job-cards-parent {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .job-detail-header {
        flex-wrap: wrap;
    }

    .job-actions {
        margin-top: 1rem;
    }
}

[contenteditable="true"] {
    outline: none;
    background-color: var(--bg-light-gray);
    border-radius: var(--border-radius);
    border: 1px dashed var(--gray-600);
    padding: 0.5rem 0.75rem;
    color: var(--gray-900);
}

[contenteditable="true"]:empty:before {
    content: attr(data-placeholder);
    color: var(--gray-600);
}

.course-image-attachment {
    margin-top: 0.25rem;
    background-color: var(--bg-light-gray);
    border-radius: var(--border-radius);
    border: 1px dashed var(--gray-600);
    padding: 0.5rem 0.75rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.btn-delete-tag {
    cursor: pointer;
}

.chapter-edit {
    border: 1px solid var(--dark-border-color);
    border-radius: var(--border-radius);
    padding: 1rem;
    margin-bottom: 1rem;
}

.chapter-edit .lessons {
    margin-left: 0;
    margin-top: 2rem;
}

.chapter-parent {
    margin-bottom: 1rem;
}

.chapter-edit .chapter-title {
    padding: 0.5rem 0;
}

.course-card-pills[contenteditable="true"] {
    box-shadow: none;
}

.preview {
    display: flex;
    align-items: center;
    font-size: var(--text-md);
}

.table {
    margin-bottom: 0;
}

.quiz-card {
    border: 1px solid var(--dark-border-color);
    border-radius: var(--border-radius);
    padding: 1.25rem;
    margin-top: 1.25rem;
    font-size: var(--text-base);
}

.option-input {
    width: 45%;
    margin-right: 1rem;
}

.option-checkbox {
    width: 15%;
    display: flex;
    align-items: center;
}

.preview-video-header {
    position: relative;
}

.preview-info {
    position: absolute;
    top: 0;
    right: -30px;
}

.tool-tip {
    position: relative;
    display: inline-block;
}

.tool-tip .tooltiptext {
    visibility: hidden;
    width: 30rem;
    background-color: var(--gray-800);
    color: var(--fg-color);
    padding: 1rem;
    border-radius: var(--border-radius-md);
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
}

.tool-tip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltiptext ul {
    padding: 1rem;
}

.medium {
    font-size: var(--text-base);
}

.quiz-row {
    position: relative;
    color: var(--text-color);
    cursor: pointer;
}

.course-creation-link {
    float: right;
}

@media (max-width: 500px) {
    .course-creation-link {
        float: inherit;
        margin-bottom: 1rem;
    }
}

.indicator-pill::before {
    width: 0;
    height: 0;
    margin-right: 0;
}

.role {
    margin-bottom: 0;
    cursor: pointer;
}

@media (min-width: 500px) {
    .role:last-child {
        margin-left: 5rem
    }
}

.icon-xl {
    width: 2.75rem;
    height: 2.75rem;
}

.modal .comment-field {
    height: 150px !important;
    resize: auto !important;
}

.notification-card {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    position: relative;
}

.notification-card:last-child {
    margin-bottom: 0;
}

.timestamp {
    font-size: var(--text-xs);
}

.stats-parent {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 2rem;
}

.stats-label {
    color: var(--gray-900);
    font-weight: 500;
}

.stats-value {
    color: var(--gray-900);
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: 2rem;
}

.indicator-pill.green::before {
    height: 0;
    width: 0;
    border-radius: 0;
    margin-right: 0;
}

.modal-header {
    padding: 1.5rem 1.5rem 0 !important;
}

.modal-body {
    padding: 0 1.5rem !important;
}

.modal-footer {
    padding: 0.75rem 1.5rem !important;
}

.modal-content {
    font-size: var(--text-base) !important;
}

.modal-header, .modal-body {
    margin-bottom: 1rem !important;
}

.modal-header {
    border-bottom: none !important;
}

.modal-footer {
    border-top: none !important;
    background-color: var(--gray-200) !important;
    justify-content: flex-end !important;
}

.modal-header .modal-title {
    color: var(--gray-900);
}

.frappe-chart .title {
    font-size: 1rem;
    font-weight: bold;
    color: var(--gray-900);
}

.course-description-section {
    padding-bottom: 4rem;
}

input::-webkit-file-upload-button {
  border-radius: var(--border-radius);
  font-size: var(--text-md);
  padding: 0.25rem 1.25rem;
  border: none;
  color: var(--text-color);
  cursor: pointer;
}

input::file-selector-button {
  border-radius: var(--border-radius);
  font-size: var(--text-md);
  padding: 0.25rem 1.25rem;
  border: none;
  color: var(--text-color);
  cursor: pointer;
}

input::-webkit-file-upload-button:hover {
  background-color: var(--gray-400);
}

input::file-selector-button:hover {
  background-color: var(--gray-400);
}

.btn {
  font-weight: 400;
}

select {
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
}
